<template>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
    <div class="card">
      <div class="card-header">Time Shedule</div>
        <div class="card-body">
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="budget-list-block">
                <div class="row">
                  <div id="slide-panel1" class="slide-panel-light" style="position: fixed; width: 275px; transition: all 450ms ease 0s; height: 100%; top: 0px; right: -275px; z-index: 1049;">
                    <h3>Time Shedule</h3>
                    <form>
                      <div class="row">
                        <div class="col-xl54 col-lg-5 col-md-6 col-sm-6 col-6 pb-0"><h6>Starting</h6></div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 pb-0">
                          <div class="form-group">
                            <input type="time" id="start_time" name="start_time" v-model="request_timing.start_time" min="07:00" max="18:00" style="border: solid #9e9e9e 0.5px;padding: 13px;border-radius: 3px;" required>
                          </div>
                        </div>
                        <div class="col-xl-5 col-lg-5 col-md-12 col-sm-6 col-6 pt-0 pb-0"><h6>Closing</h6></div>
                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div class="form-group">
                              <input type="time" id="end_time" name="end_time" v-model="request_timing.end_time" min="09:00" max="18:00" style="border: solid #9e9e9e 0.5px;padding: 13px;border-radius: 3px;" required>
                            </div>
                          </div>
                          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6 col-6"><h4>Break</h4></div>                                        
                          <div class="col-xl-5 col-lg-5 col-md-12 col-sm-6 col-6 pb-0 pt-0"><h6>Starting</h6></div>
                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 pb-0">
                            <div class="form-group">
                              <input type="time" id="break_start_time" name="break_start_time" v-model="request_timing.break_start_time" min="09:00" max="18:00" style="border: solid #9e9e9e 0.5px;padding: 13px;border-radius: 3px;" required>
                            </div>
                          </div>
                          <div class="col-xl-5 col-lg-5 col-md-12 col-sm-6 col-6 pt-0 pb-0"><h6>Closing</h6></div>
                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div class="form-group">
                              <input type="time" id="break_end_time" name="break_end_time" v-model="request_timing.break_end_time" min="09:00" max="18:00" style="border: solid #9e9e9e 0.5px;padding: 13px;border-radius: 3px;" required>
                            </div>
                          </div>
                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div class="form-group">
                              <a href="" v-on:click="submitTiming" class="btn btn-success text-white">Save</a>
                            </div>
                          </div>
                        </div>
                    </form>
                  </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" v-if="timing && timing.start_time && timing.end_time">
                  <p>{{timing.start_time}} - {{timing.end_time}} | Break: {{timing.break_start_time}} - {{timing.break_end_time}} </p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" v-else><p>Set Time</p></div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 setbtn">
                  <a v-on:click="openSlider(1)" class="btn btn-default" id="trigger6">Set Working Hours</a>
                </div>
              </div>
              <br>
              <div class="row">
                <div id="slide-panel2" class="slide-panel-light" style="position: fixed; width: 275px; transition: all 450ms ease 0s; height: 100%; top: 0px; right: -275px; z-index: 1049;">
                  <h3>Time Shedule</h3>
                  <form>
                    <div class="row">
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6 col-6"><h6>Starting</h6></div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                        <div class="form-group">
                          <v-select :items="days" item-text="text" item-value="value" v-model="update_timing.start_day" outlined></v-select>
                        </div>
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0"><h6>Closing</h6></div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                        <div class="form-group">
                          <v-select :items="days" item-text="text" item-value="value" v-model="update_timing.end_day" outlined></v-select>
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <div class="form-group">
                          <a v-on:click="submitDays" class="btn btn-success text-white">Save</a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" v-if="timing && timing.start_day && timing.end_day">
                  <p>{{getDay(timing.start_day)}} to {{getDay(timing.end_day)}}</p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" v-else><p>Set Day</p></div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 setbtn">
                  <a v-on:click="openSlider(2)" class="btn btn-default" id="trigger2">Set Working Days</a>
                </div>
              </div>
              <br>
              <div class="row">
                <div id="slide-panel3" class="slide-panel-light" style="position: fixed; width: 275px; transition: all 450ms ease 0s; height: 100%; top: 0px; right: -275px; z-index: 1049;">
                  <h3>Time Shedule</h3>
                  <form>
                    <div class="row">
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6 col-6"><h6>Select Year</h6></div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                        <div class="form-group">
                          <v-select :items="year" item-text="text" item-value="text" v-model="update_timing.year" outlined></v-select>
                        </div>
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6 col-6 pt-0"><h6>Starting</h6></div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                        <div class="form-group">
                          <v-select :items="months" item-text="text" item-value="value" v-model="update_timing.start_month" outlined></v-select>
                        </div>
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0"><h6>Closing</h6></div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                        <div class="form-group">
                          <v-select :items="months" item-text="text" item-value="value" v-model="update_timing.end_month" outlined></v-select>
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <div class="form-group">
                          <a v-on:click="submitMonths" class="btn btn-success text-white">Save</a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" v-if="timing && timing.start_month && timing.end_month">
                  <p>{{getMonth(timing.start_month)}} to {{getMonth(timing.end_month)}}</p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" v-else><p>Set Month</p></div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 setbtn">
                  <a v-on:click="openSlider(3)" class="btn btn-default" id="trigger3">Set Working Months</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name:'Tutor-Timing',
  props:['tutor'],
  data(){
    return {
      timing:{
        tutor_id:null,
        break_end_time:null,
        break_start_time:null,
        end_day:null,
        end_time:null,
        end_month:null,
        start_day:null,
        start_month:null,
        start_time:null,
        year:null,
      },
      update_timing:{
        start_time:null,
        end_time:null,
        break_start_time:null,
        break_end_time:null,
      },
      request_timing:{
        start_time:null,
        end_time:null,
        break_start_time:null,
        break_end_time:null,
      },
      update_days:{
        start_day:null,
        end_day:null, 
      },
      days:[
        {value:1,text:'Sunday'},
        {value:2,text:'Monday'},
        {value:3,text:'Tuesday'},
        {value:4,text:'Wedensday'},
        {value:5,text:'Thursday'},
        {value:6,text:'Friday'},
        {value:7,text:'Saturday'},
      ],
      months:[
        {value:1,text:'January'},
        {value:2,text:'February'},
        {value:3,text:'March'},
        {value:4,text:'April'},
        {value:5,text:'May'},
        {value:6,text:'June'},
        {value:7,text:'July'},
        {value:8,text:'August'},
        {value:9,text:'September'},
        {value:10,text:'October'},
        {value:11,text:'November'},
        {value:12,text:'December'},
      ],
      year:[
        {value:1,text:new Date().getFullYear()},
        {value:2,text:new Date().getFullYear()+1}
      ],
      result:null
    }
  },
  beforeMount(){
    if(this.tutor){
      if(this.tutor.timing){
        this.timing = this.tutor.timing;
        this.request_timing.start_time = this.tutor.timing.start;
        this.request_timing.end_time = this.tutor.timing.end;
        this.request_timing.break_start_time = this.tutor.timing.break_start;
        this.request_timing.break_end_time = this.tutor.timing.break_end;
      }
    }
  },
  methods:{
    openSlider(id){
      document.getElementById('slide-panel'+id).style.right = '0px';
      document.getElementById('slide-panel'+id).style.width = '275px';
      document.getElementById('slide-reveal-overlay').style.display = 'block';
    },
    closeSlideBar(id){
      document.getElementById('slide-panel'+id).style.right = '-275px';
      document.getElementById('slide-panel'+id).style.width = '0px';
      document.getElementById('slide-reveal-overlay').style.display = 'none';
    },
    getDay(id){
      for(var i = 0; i<this.days.length; i++){
        if(this.days[i].value == id){
          return this.days[i].text;
        }
      }
    },
    getMonth(id){
      for(var i = 0; i<this.months.length; i++){
        if(this.months[i].value == id){
          return this.months[i].text;
        }
      }
    },
    submitTiming(e){
      e.preventDefault();
      this.request_timing.tutor_id = this.tutor.tutor_id;
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/tutor_worktime',this.request_timing,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.message == 'work Time Created successfully'){
          this.timing = {};
          this.timing.tutor_id = this.tutor.tutor_id;
          this.timing.start_time = res.data.data.start_time;
          this.timing.end_time = res.data.data.end_time;
          this.timing.break_start_time = res.data.data.break_start_time;
          this.timing.break_end_time = res.data.data.break_end_time;
          this.timing.start_day = res.data.data.start_day;
          this.timing.end_day = res.data.data.end_day;
          this.timing.start_month = res.data.data.start_month;
          this.timing.end_month = res.data.data.end_month;
          this.tutor.timing = {};
          this.tutor.timing.start_time = this.timing.start_time;
          this.tutor.timing.end_time = this.timing.end_time;
          this.tutor.timing.break_start_time = this.timing.break_start_time;
          this.tutor.timing.break_end_time = this.timing.break_end_time;

          this.closeSlideBar(1);
        }
      })
    },
    submitDays(e){
      e.preventDefault();
      let query = {};
      query.tutor_id = this.timing.tutor_id;
      query.start_day = (this.update_timing.start_day)?this.update_timing.start_day:null;
      query.end_day = (this.update_timing.end_day)?this.update_timing.end_day:null;
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/tutor_worktime', query,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.message == 'work Time Created successfully'){
          this.timing = res.data.data;
          this.tutor.timing.start_day = this.update_timing.start_day;
          this.tutor.timing.end_day = this.update_timing.end_day;
          this.closeSlideBar(2);
        }
      });
    },
    submitMonths(e){
      e.preventDefault();
      let query = {};
      query.tutor_id = this.timing.tutor_id;
      query.start_month = (this.update_timing.start_month)?this.update_timing.start_month:null;
      query.end_month = (this.update_timing.end_month)?this.update_timing.end_month:null;
      query.year = (this.update_timing.year)?this.update_timing.year:null;
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/tutor_worktime', query,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.message == 'work Time Created successfully'){
          this.timing = res.data.data;
          this.closeSlideBar(3);
          this.tutor.timing.start_month = this.update_timing.start_month;
          this.tutor.timing.end_month = this.update_timing.end_month;
        }
      });
    }
  }
}
</script>

<style scoped>
.card {
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    margin-bottom: 30px;
    border-radius: 2px;
}
.card-header {
    background: #fbfbfb;
    border-bottom: 1px solid #e6e5e7;
    color: #252328;
    padding: .75rem 1.25rem;
    margin-bottom: 0;
}
.setbtn a.btn {
    float: right !important;
    width: 200px;
}
.slide-panel-light {
    background-color: #fff;
    padding: 30px;
    text-align: left;
    overflow: scroll;
}
</style>